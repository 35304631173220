
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default {
  name: "TimelapseHelpDialog",
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    dialog(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.TimelapseToggleHelpDialog, {
        visible,
      })
    },
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
  },
}
