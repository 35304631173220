
import { mapStores } from "pinia"
import { useTimelapseStore } from "@/stores/timelapse"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

export default {
  name: "TimelapseSaveDialogEffectsStep",
  computed: {
    ...mapStores(useTimelapseStore),
  },
  methods: {
    toggleSmoothTransition(active) {
      this.$analytics.saveEvent(AnalyticsEvent.TimelapseSelectOption, {
        option: "smoothTransition",
        active,
      })
    },
    toggleRemoveTimestamp(active) {
      this.$analytics.saveEvent(AnalyticsEvent.TimelapseSelectOption, {
        option: "removeTimestamp",
        active,
      })
    },
  },
}
