
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import TimelapseSidebar from "@/components/timelapse/TimelapseSidebar"
import TimelapsePlayer from "@/components/timelapse/TimelapsePlayer"
import { debounce, updateQuery } from "@evercam/shared/utils"
import {
  TimelapsePeriod,
  TimelapseSchedules,
  TimelapseFilterQueryParams,
  TimelapseSchedule,
  TimelapseDuration,
} from "@evercam/shared/types/timelapse"
import Vue from "vue"
import { mapStores } from "pinia"
import { useTimelapseStore } from "@/stores/timelapse"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCameraStore } from "@/stores/camera"
import { useLayoutStore } from "@/stores/layout"
import { camelizeKeys } from "humps"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import { subtractOneHourFromEndIntervals } from "@evercam/shared/constants/schedules"

export default Vue.extend({
  name: "TimeLapse",
  meta: {
    pageId: AnalyticsEventPageId.Timelapse,
  },
  components: {
    TimelapseSidebar,
    RightSidebarContent,
    TimelapsePlayer,
  },
  middleware: ["ensureCameraRoute"],
  async asyncData({ redirect, from, app, route }) {
    const timelapseStore = useTimelapseStore()
    const snapshotStore = useSnapshotStore()
    const cameraStore = useCameraStore()

    const camelizedKeys = camelizeKeys(route.params) as {
      cameraExid: string
      projectExid: string
    }
    const routeCameraExid = camelizedKeys?.cameraExid

    if (!app.$permissions.user.can.access.timelapse()) {
      return redirect(cameraStore.cameraRoute)
    }

    if (from.query.duration) {
      timelapseStore.selectedDuration = parseInt(
        (from.query.duration || TimelapseDuration.SixtySeconds) as string
      )
    }
    if (from.query.period) {
      timelapseStore.selectedPeriod = from.query.period as TimelapsePeriod
      if (from.query.period == TimelapsePeriod.Custom) {
        if (from.query.from) {
          timelapseStore.fromDatetime = from.query.from as string
        }
        if (from.query.to) {
          timelapseStore.toDatetime = from.query.to as string
        }
      }
    }
    if (from.query.schedule) {
      timelapseStore.schedule = from.query
        .schedule as unknown as TimelapseSchedules
    }

    await snapshotStore.updateLatestAndOldestSnapshots(routeCameraExid)
  },
  data() {
    return {
      playerParams: null,
      logo: false,
      logoPosition: {},
      playerDimensions: {} as { width: string; height: string },
    }
  },
  head() {
    return {
      title: `${this.cameraStore.selectedCamera?.name || "Evercam"} | ${this.$t(
        "pages.timelapse"
      )}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useTimelapseStore, useCameraStore, useLayoutStore),
  },
  watch: {
    snapshots: debounce(function () {
      // @ts-ignore
      this.refreshPlayer()
    }),
    "cameraStore.isCameraTab"() {
      this.$setTimeout(() => this.updatePlayerDimensions())
    },
  },
  mounted() {
    this.refreshPlayer()
    this.$nextTick(this.updatePlayerDimensions)
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.layoutStore.enableRightSidebar()
  },
  beforeDestroy() {
    this.layoutStore.disableRightSidebar()
    this.timelapseStore.unsetProperties()
  },
  methods: {
    updatePlayerDimensions() {
      const cameraTabsHeight =
        this.$globalRefs.cameraTabs?.getBoundingClientRect()?.height || 0
      const appBarHeight =
        this.$globalRefs.appBar?.getBoundingClientRect()?.height || 0
      const leftSideBarWidth =
        this.$globalRefs.sideBar?.getBoundingClientRect()?.width || 0
      const rightSidebarWidth = this.layoutStore.isRightSidebarVisible
        ? this.$globalRefs.rightSidebar?.getBoundingClientRect()?.width || 0
        : 0
      const topBarsHeight = cameraTabsHeight + appBarHeight

      this.playerDimensions = {
        width: window.innerWidth,
        height: window.innerHeight - topBarsHeight,
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.playerDimensions = {
          ...this.playerDimensions,
          width: window.innerWidth - rightSidebarWidth - leftSideBarWidth,
        }
      }
    },
    refreshPlayer() {
      const schedule = subtractOneHourFromEndIntervals(
        this.timelapseStore.schedule
      ) as TimelapseSchedule
      this.playerParams = {
        start: this.timelapseStore.fromDatetime,
        end: this.timelapseStore.toDatetime,
        count: parseInt(this.timelapseStore.selectedDuration) * 2,
        schedule,
      }

      let params: TimelapseFilterQueryParams = {
        to: this.timelapseStore.toDatetime,
        period: this.timelapseStore.selectedPeriod,
        duration: parseInt(this.timelapseStore.selectedDuration),
        schedule,
      }
      if (this.timelapseStore.fromDatetime) {
        params.from = this.timelapseStore.fromDatetime
      }
      updateQuery(params)
    },
  },
})
