
import { mapStores } from "pinia"
import { useTimelapseStore } from "@/stores/timelapse"
import { useCameraStore } from "@/stores/camera"

export default {
  name: "TimelapseSaveDialogFinalStep",
  computed: {
    ...mapStores(useTimelapseStore),
  },
  watch: {
    "timelapseStore.stepper": {
      handler(step) {
        if (step === 3) {
          this.updateTimelapseTitle()
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateTimelapseTitle() {
      const { selectedCamera, selectedCameraTimezone } = useCameraStore()
      const formatDate = (date) =>
        this.$moment(date).tz(selectedCameraTimezone).format("YYYY-MM-DD HH:mm")
      const fromDate = formatDate(this.timelapseStore.fromDatetime)
      const toDate = formatDate(this.timelapseStore.toDatetime)
      this.timelapseStore.title = `Evercam - ${selectedCamera?.name} - ${fromDate} - ${toDate} (MP4 ${this.timelapseStore.selectedDuration} sec.)`
    },
  },
}
