
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { mapStores } from "pinia"
import { useRecordingsStore } from "@/stores/recordings"
import { useTimelapseStore } from "@/stores/timelapse"
import { useCameraStore } from "@/stores/camera"
import { useLayoutStore } from "@/stores/layout"
import { useAccountStore } from "@/stores/account"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer"
export default {
  name: "TimelapsePlayer",
  components: {
    EvercamPlayer,
    EvercamLoadingAnimation,
  },
  props: {
    playerParams: {
      type: Object,
      default: () => ({}),
    },
    logo: {
      type: [Boolean, String],
      default: false,
    },
    width: {
      type: [String, Number],
      default: 0,
    },
    height: {
      type: [String, Number],
      default: 0,
    },
    snapshotRange: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      logoWidth: 0,
      logoHeight: 0,
      configKonva: {
        width: 0,
        height: 0,
        keepRatio: true,
      },
      configLogo: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        draggable: true,
        image: new Image(),
        name: "logo",
        dragBoundFunc: this.handleDragBound,
      },
      paramsLogo: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        scaleX: 1,
        scaleY: 1,
      },
      configTransformer: {
        keepRatio: true,
        enabledAnchors: [
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ],
        rotateEnabled: false,
      },
    }
  },
  computed: {
    ...mapStores(
      useTimelapseStore,
      useRecordingsStore,
      useCameraStore,
      useLayoutStore,
      useAccountStore
    ),
    konva() {
      return {
        width: `${this.imageWidth}px`,
        height: `${this.imageHeight}px`,
        position: "absolute",
        margin: "auto",
        zIndex: 3,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }
    },
    evercamWatermarkDimensions() {
      const size = Math.floor(this.imageWidth / 24)

      return `
        width: ${size}px;
        height: ${size}px;
      `
    },
  },
  watch: {
    logo: {
      immediate: true,
      handler(logoUrl) {
        if (!logoUrl) {
          return
        }
        let logo = new Image()
        logo.src = logoUrl
        logo.onload = () => {
          this.configLogo.image = logo
          this.configLogo.width = this.paramsLogo.width =
            (this.imageWidth * 10) / 100
          this.configLogo.height = this.paramsLogo.height =
            (logo.height * this.configLogo.width) / logo.width
          this.configLogo.x = this.paramsLogo.x = Math.floor(
            (this.imageWidth - this.configLogo.width) / 2
          )
          this.configLogo.y = this.paramsLogo.y = Math.floor(
            (this.imageHeight - this.configLogo.height) / 2
          )
          this.logoWidth = this.configLogo.width
          this.logoHeight = this.configLogo.height
          const transformerNode = this.$refs.transformer?.getStage()
          const stage = transformerNode?.getStage()
          var shapes = stage?.find(".logo")
          transformerNode?.nodes(shapes)
          this.$emit(
            "logo-position",
            this.getNormalizedLogoPosition(this.paramsLogo)
          )
        }
      },
    },
    paramsLogo: {
      deep: true,
      immediate: true,
      handler(params) {
        this.$emit("logo-position", this.getNormalizedLogoPosition(params))
      },
    },
  },
  methods: {
    toggleIosFullscreen() {
      this.cameraStore.isCameraTab = !this.cameraStore.isCameraTab
      this.layoutStore.toggleAppBarVisibility()
    },
    initLogo(imgElement) {
      setTimeout(() => {
        const img = imgElement?.getBoundingClientRect()
        if (!img) {
          return
        }
        this.imageWidth = img.width
        this.imageHeight = img.height
        this.configKonva.width = img.width
        this.configKonva.height = img.height
        const transformerNode = this.$refs.transformer?.getStage()
        const stage = transformerNode?.getStage()
        var shapes = stage?.find(".logo")
        transformerNode?.nodes(shapes)
      }, 500)
    },
    handleStageMouseDown(e) {
      if (this.$refs.transformer) {
        if (e.target === e.target.getStage()) {
          this.selectedShapeName = ""
          this.updateTransformer()

          return
        }
        const clickedOnTransformer =
          e.target.getParent().className === "Transformer"
        if (clickedOnTransformer) {
          return
        }
        this.selectedShapeName = e.target.name()
        this.updateTransformer()
      }
    },
    updateTransformer() {
      const transformerNode = this.$refs.transformer?.getStage()
      const stage = transformerNode?.getStage()
      const { selectedShapeName } = this
      const selectedNode = stage.findOne("." + selectedShapeName)
      if (selectedNode === transformerNode.node()) {
        return
      }
      if (selectedNode) {
        transformerNode.attachTo(selectedNode)
      } else {
        transformerNode.detach()
      }
      transformerNode.getLayer().batchDraw()
    },
    handleDragStart() {
      this.isDragging = true
    },
    handleDragEnd(e) {
      this.isDragging = false
      this.paramsLogo.x = e.target.x()
      this.paramsLogo.y = e.target.y()

      this.configLogo.x = e.target.x()
      this.configLogo.y = e.target.y()
    },
    handleTransformEnd(e) {
      this.paramsLogo.scaleX = e.target.scaleX()
      this.paramsLogo.scaleY = e.target.scaleY()

      this.configLogo.scaleX = e.target.scaleX()
      this.configLogo.scaleY = e.target.scaleY()
    },
    handleDragBound(pos) {
      let rightLimit = this.configKonva.width - this.logoWidth
      let bottomLimit = this.configKonva.height - this.logoHeight
      var newX = pos.x < 0 ? 0 : pos.x // left edge check
      newX = newX > rightLimit ? rightLimit : newX // right edge check
      var newY = pos.y < 0 ? 0 : pos.y // top edge check
      newY = newY > bottomLimit ? bottomLimit : newY // bottom edge check

      return {
        x: Math.floor(newX),
        y: Math.floor(newY),
      }
    },
    handleTransform() {
      const transformerNode = this.$refs.transformer?.getStage()
      var maxWidth = Math.floor(this.configKonva.width / 2)
      var maxHeight = Math.floor(this.configKonva.height / 2)
      transformerNode.boundBoxFunc((oldShape, newShape) => {
        let rightLimit = this.configKonva.width - newShape.width
        let bottomLimit = this.configKonva.height - newShape.height
        if (
          newShape.width > maxWidth ||
          newShape.height > maxHeight ||
          newShape.width < 1 ||
          newShape.height < 1 ||
          newShape.x < 0 ||
          newShape.y < 0 ||
          newShape.x > rightLimit ||
          newShape.y > bottomLimit
        ) {
          this.logoWidth = oldShape.width
          this.logoHeight = oldShape.height

          return oldShape
        }
        this.logoHeight = newShape.height
        this.logoWidth = newShape.width

        return newShape
      })
    },
    getNormalizedLogoPosition(params) {
      const x = params.x / this.imageWidth
      const y = params.y / this.imageHeight

      return {
        width: (params.width * params.scaleX) / this.imageWidth,
        height: (params.height * params.scaleY) / this.imageHeight,
        // Min 0 and max 1
        x: x > 1 ? 0.999 : x < 0 ? 0.01 : x,
        y: y > 1 ? 0.999 : y < 0 ? 0.01 : y,
      }
    },
  },
}
