
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types"
export default {
  name: "TimelapseSaveDialogFinishStep",
  props: {
    endMessage: {
      type: String,
      default: "",
    },
    timelapseCreationError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useProjectStore, useCameraStore),
    mediaHubLink() {
      return `${this.projectStore.projectRoute}/media-hub`
    },
  },
  methods: {
    goToMediaHub() {
      this.$analytics.saveEvent(AnalyticsEvent.TimelapseGoToMediaHub)
    },
  },
}
